import React from "react";
import {
	BlueSquares1,
	BlueSquares2,
	PinkCircles0,
	PinkCircles1,
} from "@assets/Background";
import { PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { StaticImage } from "gatsby-plugin-image";
import { pinkText } from "@components/Typography/Typography";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import {
	ArrowLinkButton,
	GetStartedButton,
} from "@components/Button_new/Button";
import TryGenemodForFree from "@components/TryGenemodForFree/Index";
import { StandardizeResearchData } from "@components/StandardizeResearchData/StandardizeResearchData";
import * as styles from "../../CommonResourcesOverview.module.scss";

export default function NonProfit() {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "non-profit" }}>
			<LabAutomationSoftware />
			<ForNonProfits />
			<AffordablePlans />
			<TryGenemodForFree withSquares />
			<TrustedBy backgroundType="solidLightPink" />
			<StandardizeResearchData />
		</PageLayout>
	);
}

const LabAutomationSoftware = () => (
	<BackgroundFadeContainer type="blueFade" skewBottom>
		<BackgroundShapes
			leftShape={{ shape: <BlueSquares1 />, xOffset: 200 }}
			rightShape={{
				shape: <BlueSquares2 />,
				xOffset: -200,
				yOffset: 200,
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<SectionTitle
					title="Lab automation software built with non-profits in mind"
					subtitle="Genemod provides non-profits with low-cost, easy-to-use lab automation software needed to scale life sciences R&D."
					textColor="ui-01"
					titleWidth={694}
					subtitleWidth={670}
					marginTop={163}
					marginTopTablet={186}
					marginTopMobile={146}
				/>
				<GetStartedButton />

				<StaticImage
					loading="eager"
					style={{ maxWidth: 1200 }}
					src="../../../assets/products/subproject_large.png"
					alt="example screenshot of project organization webpage"
					className={styles.fullWidthImage}
				/>
			</div>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const ForNonProfits = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes leftShape={{ shape: <PinkCircles0 /> }}>
			<SectionTitle
				title="Genemod for non-profit organizations"
				textColor="text-dark"
				titleWidth={862}
				marginBottom={60}
				marginBottomTablet={12}
				marginBottomMobile={12}
			/>
			<ImageAndBulletPoints
				bullets={[
					{
						title: "Set your NPO up for success from day one",
						text: (
							<>
								{
									"Nonprofit organizations get a 40% discount on paid Genemod plans to support the important work you’re doing."
								}{" "}
								<div style={{ marginTop: 20 }}>
									<ArrowLinkButton
										color="marine"
										link="CONTACT_US"
										state={{
											defaultSubject: "Billing",
										}}
									>
										Contact us
									</ArrowLinkButton>
								</div>
							</>
						),
					},
					{
						title: "A source of truth for your inventory",
						text: "From reagents to custom item types - we customize to match exactly how it is in the lab.",
					},
					{
						title: "Finally, an ELN built for scientists",
						text: "It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/Freezer_box.png"
						alt="freezer box example page"
						width={804}
						height={586}
					/>
				}
				withImageShadowMargins
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "text-dark",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
				imagePosition="left"
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const AffordablePlans = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes
			rightShape={{ shape: <PinkCircles1 />, yOffset: 340 }}
		>
			<SectionTitle
				title={pinkText`We have the most ${"affordable"} paid plans you’ll find (the same cost as a latte a week)`}
				textColor="text-dark"
				titleWidth={1094}
				marginBottom={60}
				marginBottomTablet={60}
			/>
			<ImageAndBulletPoints
				bullets={[
					{
						title: "Where project management meets electronic lab notebook",
						text: "Group similar research experiments in subprojects and see how Genemod helps you optimize your time and resources.",
					},
					{
						title: pinkText`Integrate your ${"research workflows"} and database`,
						text: "We integrate with Google Drive and more. Feel the power of file management & Genemod for research.",
					},
					{
						title: "We’ve built your favorite Excel-like features to make data entry painless",
						text: "Keep track of item types, concentrations, attachments, and notes of any samples and reagents stored in your lab.",
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/protocol-library.png"
						alt="screenshot of protocol library tool"
						width={804}
						height={586}
					/>
				}
				withImageShadowMargins
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "text-dark",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);
